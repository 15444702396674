import { DestroyRef, Injectable, input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import {
	BaseContentService,
	Content,
	IContent,
	IContentConfig,
	IRecord,
	IRecordFilter,
	IReportConfig,
	ReportEventType
} from '@soleran/contracts';
import { ContentEvent } from '@soleran/ngx-content';
import { ExportDialogService } from '@soleran/ngx-import-export';
import { FormMode } from '@soleran/ngx-layout-utility';
import { ModuleService } from '@soleran/ngx-module';
import { RecordService } from '@soleran/ngx-record';
import { ReportConfigService } from '@soleran/ngx-report';
import {
	combineLatest,
	from,
	map,
	mergeMap,
	Observable,
	of,
	shareReplay,
	take,
	tap,
	withLatestFrom
} from 'rxjs';
import { GlobalDrawerService } from '../../global-drawer/global-drawer.service';
import { RecordModalComponent } from '../modals/record/record-modal.component';
import { Store } from '@ngrx/store';
import { LayoutSelectors } from '../../_state/layout/selectors';
import { ObjectSelectors } from '../../_state/object/selectors';
import { AppRouterSelectors } from '../../_state/app/selectors';

const REPORT_DIRECTORY_URL = 'https://localhost:4200/report/directory';

@Injectable({
	providedIn: 'root'
})
export class ReportContentService extends BaseContentService {
	reportConfigCache: Record<string, { reportConfig: any; timestamp: number }> = {};

	constructor(
		private _router: Router,
		private _reportConfigService: ReportConfigService,
		private _moduleService: ModuleService,
		private _recordService: RecordService,
		private _store: Store,
		private _drawerService: GlobalDrawerService,
		private _dialog: ExportDialogService,
		private _destroyRef: DestroyRef
	) {
		super();
	}

	override canHandle(type: Content): boolean {
		return type === Content.Report;
	}

	override getInputs(contentId: any): Observable<any> {
		const id = contentId.identifier;
		const record$ = this._store.select(LayoutSelectors.record);
		const module$ = this._store.select(ObjectSelectors.selectedObject);
		const reportConfig$ = this._getReportConfig(id);
		const filteredReportConfig$ = combineLatest([reportConfig$, module$, record$]).pipe(
			take(1),
			map(([reportConfig, module, record]) => {
				if (!module || !record) return reportConfig;
				const filteredReportConfig = { ...reportConfig };
				const recordFilter: IRecordFilter = {
					moduleId: module.id,
					recordId: record.id
				};
				filteredReportConfig.recordFilter = recordFilter;
				return filteredReportConfig;
			})
		);
		const inputs$ = filteredReportConfig$
			.pipe(shareReplay({ bufferSize: 1, refCount: true }))
			.pipe(map((reportConfig) => ({ reportConfig })));
		return inputs$;
	}
	private _getReportConfig(contentId) {
		const currentTime = Date.now();

		if (
			this.reportConfigCache[contentId] &&
			currentTime - this.reportConfigCache[contentId].timestamp < 60000
		) {
			return of(this.reportConfigCache[contentId].reportConfig);
		}
		const reportConfig$ = this._reportConfigService.get(contentId).pipe(
			tap((reportConfig) => {
				this.reportConfigCache[contentId] = { reportConfig, timestamp: currentTime };
			})
		);
		return reportConfig$;
	}

	override get = (config: IContentConfig) => {
		const module = config.module;
		return this._reportConfigService.get().pipe(
			map((reportConfigs) => reportConfigs.filter((reportConfig) => !reportConfig.isTemplate)),
			map((reportConfigs) => {
				if (!module) return reportConfigs;
				const childrenModuleIds = module.relationships
					.filter((relationship) => !relationship.isParent)
					.map((relaionship) => relaionship.relatedModuleId);
				const eligibleModuleIds = [module.id].concat(childrenModuleIds);
				return reportConfigs.filter((reportConfig) =>
					eligibleModuleIds.includes(reportConfig.moduleId)
				);
			}),
			map((reportConfigs) => {
				return reportConfigs.map((reportConfig) => {
					const content: IContent = {
						id: reportConfig.id,
						type: Content.Report,
						display: reportConfig.name
					};
					return content;
				}).sort((a,b) => a.display?.localeCompare(b.display));
			}),
		);
	};

	override getOne = (id: string) => {
		return this._reportConfigService.get(id).pipe(
			map((reportConfig) => {
				if (!reportConfig) {
					throw new Error(`Content - Report '${id}' Not Found`);
				}
				const content: IContent = {
					id: reportConfig.id,
					type: Content.Report,
					display: reportConfig.name
				};
				return content;
			})
		);
	};
	override create = (config: any) => {
		const url = `${REPORT_DIRECTORY_URL}/new`;
		window.open(url, '_blank');
		return of(undefined);
	};

	override update = (config: any) => {
		const url = `${REPORT_DIRECTORY_URL}/${config}/edit`;
		window.open(url, '_blank');
		return of(undefined);
	};

	override handleEvent = (config: ContentEvent) => {
		switch (config.contentEventType) {
			case ReportEventType.AddRecord:
				return this._handleAddRecordEvent(config);
			case ReportEventType.EditRecord:
				return this._handleEditRecordEvent(config);
			case ReportEventType.Analyze:
				return this._handleAnalyzeReportEvent(config);
			case ReportEventType.Import:
				return this._handleImportEvent(config);
			case ReportEventType.Export:
				return this._handleExportEvent(config);
			case ReportEventType.RecordLink:
				return this._handleRecordLinkEvent(config);
			case ReportEventType.EditReport:
				return this._handleEditReportEvent(config);
			default:
				return of();
		}
	};

	private _handleAddRecordEvent(config: ContentEvent) {
		const event = config.event;
		const url = this._router.url;
		const segments = url.split('/');

		const recordIndex = segments.indexOf('record');
		const isViewingRecord = recordIndex !== -1 && segments[recordIndex + 1];
		const parentModule$ = isViewingRecord
			? this._store.select(ObjectSelectors.selectedObject)
			: of(undefined);
		const parentRecord$ = isViewingRecord
			? this._store.select(LayoutSelectors.record)
			: of(undefined);

		return this._moduleService.get(event.moduleId).pipe(
			takeUntilDestroyed(this._destroyRef),
			withLatestFrom(parentModule$, parentRecord$),
			mergeMap(([module, parentModule, parentRecord]) => this._drawerService.open(RecordModalComponent, { module, parentModule, parentRecord, isNewRecord: true }))
		);
	}
	private _handleEditRecordEvent(config: ContentEvent) {
		const event = config.event;
		const module$ = this._moduleService.get(event.moduleId);
		const record$ = this._recordService
			.get(event.recordId, event.moduleId)
			.pipe(map((response: any) => response as IRecord));
		return combineLatest([module$, record$]).pipe(
			takeUntilDestroyed(this._destroyRef),
			mergeMap(([module, record]) =>
				this._drawerService.open(RecordModalComponent, { module, record, mode: FormMode.Edit })
			)
		);
	}
	private _handleImportEvent(config: ContentEvent) {
		const appPath = this._store.selectSignal(AppRouterSelectors.route)();

		if (!appPath) throw new Error('App path not found');

		const commands = [appPath, 'object', config.event.moduleId, 'import'];
		const succeeded = this._router.navigate(commands);
		return from(succeeded);
	}
	private _handleAnalyzeReportEvent(config: ContentEvent) {
		const event = config.event;
		const appRoute = this._store.selectSignal(AppRouterSelectors.route)();
		this._router.navigate([appRoute, 'report', 'metrics', event.id]);
		return of();
	}

	private _handleExportEvent(config: ContentEvent) {
		const reportConfig = config.event;
		return this._openExportDialog(reportConfig)
			.afterClosed()
			.pipe(takeUntilDestroyed(this._destroyRef));
	}
	private _handleRecordLinkEvent(config: ContentEvent) {
		const event = config.event;
		const appRoute = this._store.selectSignal(AppRouterSelectors.route)();
		const commands = [appRoute, 'object', event.fieldConfig.moduleId, 'record', event.recordId];
		this._router.navigate(commands);
		return of();
	}
	private _handleEditReportEvent(config: ContentEvent) {
		const event = config.event;
		const appRoute = this._store.selectSignal(AppRouterSelectors.route)();
		this._router.navigate([appRoute, 'report', 'directory', event.id, 'edit']);
		return of();
	}
	private _openExportDialog(reportConfig: IReportConfig) {
		this._throwIfNoReportConfigId(reportConfig);
		const ref = this._dialog.open({ reportConfigId: reportConfig.id });
		return ref;
	}
	private _throwIfNoReportConfigId(reportConfig: IReportConfig) {
		if (!reportConfig?.id) throw Error('Report config id required for export');
	}
}
