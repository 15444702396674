import { AfterViewInit, ChangeDetectorRef, Component, DestroyRef, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IFormChange, IModule, IRecord, IRecordCreateContract } from '@soleran/contracts';
import { FormMode } from '@soleran/ngx-layout-utility';
import { RecordService } from '@soleran/ngx-record';
import { GlobalDrawerService } from '../../../global-drawer/global-drawer.service';
import { toRecordUpdateContract } from 'src/app/pages/module/record/util/to-record-update-contract';
import { toRecordCreateContract } from 'src/app/pages/module/record/util/to-record-create-contract';

@Component({
	selector: 'app-record-modal',
	templateUrl: './record-modal.component.html',
	styleUrl: './record-modal.component.scss'
})
export class RecordModalComponent implements OnInit, AfterViewInit {
	@Input({ required: true }) module!: IModule;
	@Input() record?: IRecord;
	@Input() parentRecord?: IRecord;
	@Input() parentModule?: IModule;
	@Input() mode = FormMode.Edit;
	@Input() isNewRecord = false;

	constructor(
		private _globalDrawer: GlobalDrawerService,
		private _recordService: RecordService,
		private _destroyRef: DestroyRef,
		private _changeDetectorRef: ChangeDetectorRef
	) { }
	ngOnInit(): void {
		this._initParentFieldValue();
	}
	private _initParentFieldValue() {
		const parentField = this.module.relationships.find(r => r.relatedModuleId === this.parentModule?.id && r.isParent === true);
		if (parentField) {
			this.record = { ...this.record } as IRecord;
			this.record[parentField.field] = { id: this.parentRecord.id, display: this.getKeyFieldDisplay(this.parentModule, this.parentRecord) };
		}
	}
	ngAfterViewInit(): void {
		// this._changeDetectorRef.detectChanges();
	}

	formState: IFormChange<IRecord> = { valid: false, value: { id: 0 } };
	EDIT = FormMode.Edit;

	onFormChange(change: IFormChange<IRecord>) {
		this.formState = change;
		this._changeDetectorRef.detectChanges();
	}
	getKeyFieldDisplay(module: IModule, record: IRecord) {
		const keyField = module.keyField;
		return record[keyField];
	}
	cancel() {
		this._globalDrawer.close();
	}
	submit() {
		if (this.isNewRecord) {
			const createModel = toRecordCreateContract(this.module, this.formState.value);
			this._recordService
				.create(this.module.id, createModel)
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe((record) => this._globalDrawer.close(this._globalDrawer.close(record)));
		} else {
			const updateModel = toRecordUpdateContract(this.module, this.formState.value);
			this._recordService
				.update(this.record.id, this.module.id, updateModel)
				.pipe(takeUntilDestroyed(this._destroyRef))
				.subscribe((record) => this._globalDrawer.close(this._globalDrawer.close(record)));
		}
	}
}
