import { IRecord } from "@soleran/contracts";
import { FormMode, LayoutMode } from "@soleran/ngx-layout-utility";

export interface ILayoutState {
	layoutMode: LayoutMode,
	formMode: FormMode,
	record?: IRecord
}

export const LAYOUT_STATE_SLICE = 'layout';
export const LAYOUT_INITIAL_STATE: ILayoutState = {
	layoutMode: LayoutMode.View,
	formMode: FormMode.View
};